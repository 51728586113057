angular.module('globalDirectives', [])


	/**
	 * If AccessoriesApp is loaded on page, this directive can be used to
	 * open accessories selector modal outside the accessories application.
	 * You can set search filter through data-attribute to open modal with initial search parameter.
	 * You can set group filters through data-attribute to open modal with respective groups checked.
	 * 
	 * Example:
	 * 	<!-- search word is "Sadesuoja" and group 1 will be checked -->
	 *  <button accessories-selector data-freefilter="Sadesuoja" data-groupfilters="[1]">Open accessories selector</button>
	 *  
	 *  <!-- no search word, no groups checked -->
	 *  <button accessories-selector data-groupfilters="[]">Open accessories selector</button>
	 *  
	 *  <!-- no search word, groups will be checked via initial configuration object.
	 *  <button accessories-selector>Open accessories selector</button>
	 *  
	 * This will open the modal with Sadesuoja written on searchText-input and results filtered respectively.
	 */
	.directive('accessoriesSelector', [
	    function() {
	   	return {
	   		restrict: 'A',
	   		link: function(scope, element, attrs) {
	   			var $appElement = $('#accessoriesApp');
	   			if($appElement.length) {
		   			$(element).click(function(e){
		   				e.preventDefault();
		   				var accessoriesAppScope = angular.element($appElement).scope();
		   				// If element has data-freefilter set
		   				if($(this).data('freefilter')) {
		   					// Set initial search word filter value.
		   					accessoriesAppScope.setFreefilter($(this).data('freefilter'));
		   				}
		   				// If element has data-groupfilters set
		   				if($(this).data('groupfilters')) {
		   					// Set checked groups
		   					accessoriesAppScope.setGroupFilters($(this).data('groupfilters'));
		   				} else {
		   					// Use app configuration and extend checked groups from there
		   					accessoriesAppScope.setGroupFiltersFromConfig();
		   				}
		   				accessoriesAppScope.openProductsModal();
		   			});
	   			} else {
	   				$(this).hide();
	   			}
	   		}
	   	};
	    }
	])

	.directive('catalogItemImagesPreview', [
		function(){
			return {
				restrict: 'A',
				scope: {
					images: '=',
					productcode: '=',
					lbgroup: '='
				},
				template: '<span ng-repeat="(index, image_number) in images"> \
					<a href="/catalog-item-images/get-image/{{productcode}}/{{index}}" data-lightbox="{{lbgroup || productcode}}" data-title="{{productcode}}"> \
						<i ng-if="index == 0" class="fa fa-camera"></i> \
					</a> \
				</span>'
			}	
		}
	])
	
	.directive('bsTooltip', function() {
	    return function(scope, element, attrs) {
			var el = element.find("[data-toggle=tooltip]");
	        el.each(function(){
				var opts = {
					placement: $(this).attr('data-placement') || 'right'
				};
				if($(this).attr('data-container')) {
					opts.container = $(this).attr('data-container');
				}
	        	$(this).tooltip(opts);
	        });
	    };
	})
	
	.directive('bsPopover', function() {
	    return function(scope, element, attrs) {
	    	var el = element.find("[data-toggle=popover]");
	        el.each(function(){
	        	$(this).popover({ 
	        		placement: $(this).attr('data-placement') || 'right',
	        		html: $(this).attr('data-html') || false,
	        		content: $(this).attr('data-content') || ''
	        	});
	        });
	    };
	})

    .directive('bsSwitch', [
        function() {
            return {
                restrict: 'A',
                require: '?ngModel',
                link: function(scope, element, attrs, ngModel) {
                    element.bootstrapSwitch();
 
                    element.on('switchChange.bootstrapSwitch', function(event, state) {
                        if (ngModel) {
                            scope.$apply(function() {
                                ngModel.$setViewValue(state);
                            });
                        }
                    });
 
                    scope.$watch(attrs.ngModel, function(newValue, oldValue) {
                        if (newValue) {
                            element.bootstrapSwitch('state', true, true);
                        } else {
                            element.bootstrapSwitch('state', false, true);
                        }
                    });
                }
            };
        }
    ])
    
    /**
	 * Bootstrap tabs enabled by this directive
	 */
	.directive('bsTabs', function() {
		var link = function(scope, element, attrs) {
			element.click(function (e) {
			  e.preventDefault();
			  $(this).tab('show');
			});
		};
		return {
			link: link
		};
	})

    .directive('fillSpace', [
        function() {
            return {
                restrict: 'A',
                link: function(scope, element, attrs) {
                    
                },
                controller: function($scope) {
                    //console.log(element, attrs);
                    /*var elOffset = element.offset();
                    var wHeight = $(window).height();
                    var paddingBottom = attrs['data-padding-bottom'] || 10;

                    var availHeight = wHeight - elOffset.top - paddingBottom;
                    element.css('height', availHeight);
                    element.css('margin-bottom', 0);*/
                    //console.log('fillSpace - Controller');
                }
            };
        }
    ])
    
    .directive('overlayHeightTarget', [
    	function() {
    		return {
    			link: function( scope, elem, attrs ) {
    	            scope.$watch( '__height', function( newHeight, oldHeight ) {
    	                elem.attr( 'style', 'height: ' + (newHeight) + 'px' );
    	            } );
    	        }
    		};
    	}
    ])
    
    .directive('overlayHeightSource', [
    	function() {
    		return {
    			link: function( scope, elem, attrs ) {
    	            scope.$watch( function() {
    	                scope.__height = elem.height();
    	            } );
    	        }
    		};
    	}
    ])
    
    .directive('spinner', [
        function() {
        	return {
        		restrict: 'E',
        		template: function() {
        			return '<div class="spinner">' + 
        			  '<div class="rect1"></div>' +
        			  '<div class="rect2"></div>' +
        			  '<div class="rect3"></div>' +
        			  '<div class="rect4"></div>' +
        			  '<div class="rect5"></div>' +
        			'</div>';
        		}
        	}
        }
	])
	
	.directive('overlay', [
	    function() {
	    	return {
	    		restrict: 'A',
	    		template: function() {
	    			return '<div class="panel-overlay"><spinner></spinner></div>';
	    		},
	    		link: function(scope, element, attrs) {
	    			var h = $(element).parent().outerHeight();
	    			$(element).css({
	    				width: '100%',
	    				height: h,
	    				background: '#fff',
	    				opacity: '0.5',
	    				position: 'absolute',
	    				top: 0,
	    				left: 0
	    			});
	    			$(element).find('.spinner').css({'margin-top': (h/2-$(element).find('.spinner').height()) + 'px'});
	    		}
	    	}
	    }
	])
	
	.directive("confirmButton", ['$document', '$parse', function($document, $parse) {
	  return {
	    restrict: 'A',
	    link: function(scope, element, attrs) {
	      var buttonId, html, message, nope, title, yep;
	      
	      buttonId = Math.floor(Math.random() * 10000000000);
	      
	      attrs.buttonId = buttonId;
	      
	      message = attrs.message || "Are you sure?";
	      yep = attrs.yes || "Yes";
	      nope = attrs.no || "No";
	      title = attrs.title || "Confirm";
	      
	      html = "<div id=\"button-" + buttonId + "\">\n  <span class=\"confirmbutton-msg\">" + message + "</span><br>\n	<button class=\"confirmbutton-yes btn btn-danger\">" + yep + "</button>\n	<button class=\"confirmbutton-no btn btn-default\">" + nope + "</button>\n</div>";
	      
	      element.popover({
	        content: html,
	        html: true,
	        trigger: "manual",
	        title: title,
	        placement: attrs.placement || 'right'
	      });
	      
	      return element.bind('click', function(e) {
	        var dontBubble, pop;
	        dontBubble = true;
	        
	        e.stopPropagation();
	        
	        element.popover('show');
	        
	        pop = $("#button-" + buttonId);
	        
	        pop.closest(".popover").click(function(e) {
	          if (dontBubble) {
	            e.stopPropagation();
	          }
	        });
	        
	        pop.find('.confirmbutton-yes').click(function(e) {
	          dontBubble = false;
	          // Use as normal link if data-href found. 
	          // (data-href-attribute translates somehow to href in attrs-object)
	          if(attrs.href && attrs.href.indexOf('http://') !== -1) {
	          		window.location = attrs.href;
	          } else {
		          var func = $parse(attrs.confirmButton);
		          func(scope);
	          }
	        });
	        
	        pop.find('.confirmbutton-no').click(function(e) {
	          dontBubble = false;
	          
	          $document.off('click.confirmbutton.' + buttonId);
	          
	          element.popover('hide');
	        });
	        
	        $document.on('click.confirmbutton.' + buttonId, ":not(.popover, .popover *)", function() {
	          $document.off('click.confirmbutton.' + buttonId);
	          element.popover('hide');
	        });
	      });
	    }
	  };
	}])
    ;


angular.module('ui.bootstrap.buttons', [])

.constant('uibButtonConfig', {
  activeClass: 'btn-success',
  toggleEvent: 'click'
})

.controller('UibButtonsController', ['uibButtonConfig', function(buttonConfig) {
  this.activeClass = buttonConfig.activeClass || 'active';
  this.toggleEvent = buttonConfig.toggleEvent || 'click';
}])

.directive('uibBtnRadio', ['$parse', function($parse) {
  return {
    require: ['uibBtnRadio', 'ngModel'],
    controller: 'UibButtonsController',
    controllerAs: 'buttons',
    link: function(scope, element, attrs, ctrls) {
      var buttonsCtrl = ctrls[0], ngModelCtrl = ctrls[1];
      var uncheckableExpr = $parse(attrs.uibUncheckable);
      
      element.find('input').css({display: 'none'});

      //model -> UI
      ngModelCtrl.$render = function() {
        element.toggleClass(buttonsCtrl.activeClass, angular.equals(ngModelCtrl.$modelValue, scope.$eval(attrs.uibBtnRadio)));
      };

      //ui->model
      element.on(buttonsCtrl.toggleEvent, function() {
        if (attrs.disabled) {
          return;
        }

        var isActive = element.hasClass(buttonsCtrl.activeClass);

        if (!isActive || angular.isDefined(attrs.uncheckable)) {
          scope.$apply(function() {
            ngModelCtrl.$setViewValue(isActive ? null : scope.$eval(attrs.uibBtnRadio));
            ngModelCtrl.$render();
          });
        }
      });

      if (attrs.uibUncheckable) {
        scope.$watch(uncheckableExpr, function(uncheckable) {
          attrs.$set('uncheckable', uncheckable ? '' : null);
        });
      }
    }
  };
}])

.directive('uibBtnCheckbox', function() {
  return {
    require: ['uibBtnCheckbox', 'ngModel'],
    controller: 'UibButtonsController',
    controllerAs: 'button',
    link: function(scope, element, attrs, ctrls) {
      var buttonsCtrl = ctrls[0], ngModelCtrl = ctrls[1];

      element.find('input').css({display: 'none'});

      function getTrueValue() {
        return getCheckboxValue(attrs.btnCheckboxTrue, true);
      }

      function getFalseValue() {
        return getCheckboxValue(attrs.btnCheckboxFalse, false);
      }

      function getCheckboxValue(attribute, defaultValue) {
        return angular.isDefined(attribute) ? scope.$eval(attribute) : defaultValue;
      }

      //model -> UI
      ngModelCtrl.$render = function() {
        element.toggleClass(buttonsCtrl.activeClass, angular.equals(ngModelCtrl.$modelValue, getTrueValue()));
      };

      //ui->model
      element.on(buttonsCtrl.toggleEvent, function() {
        if (attrs.disabled) {
          return;
        }

        scope.$apply(function() {
          ngModelCtrl.$setViewValue(element.hasClass(buttonsCtrl.activeClass) ? getFalseValue() : getTrueValue());
          ngModelCtrl.$render();
        });
      });
    }
  };
});