var dryerPowerApp = angular.module('dryerPowerApp', [
    'globalDirectives',
    'dryerPower.services',
    'dryerPower.directives',
    'ui.bootstrap'
]);

dryerPowerApp
	.controller('indexController', [
		'$scope',
		function($scope) {
		}
	])
    /**
     * [close description]
     * @type {[type]}
     */
    .controller('dryerPowerModalController', [
		'$scope', '$modal', '$modalInstance', '$timeout', 'DryerPowerService',
		function($scope, $modal, $modalInstance, $timeout, DryerPowerService) {

            $scope.grain = {
                type: 1,
                temp: 80
            };
            $scope.valuesChanged = false;
            $scope.$watch('grain.type', function(){
                $scope.grain.temp = $scope.grain.type == 1 ? 80 : 100;
                $scope.calculate();
                $scope.valuesChanged = true;
                $timeout(function(){
                    $scope.valuesChanged = false;
                }, 1000);
            });
            $scope.$watch('grain.temp', function(){
                $scope.calculate();
                $scope.valuesChanged = true;
                $timeout(function(){
                    $scope.valuesChanged = false;
                }, 1000);
            });

            $scope.calculate = function() {
                var results = DryerPowerService.calculate(
                    $scope.grain.type,
                    $scope.grain.temp
                );
                $scope.results = results;
            };

			$scope.close = function() {
				$modalInstance.close();
			};

            $scope.calculate();

		}]);

var dryerPowerDirectives = angular.module('dryerPower.directives', []);
dryerPowerDirectives
    .directive('openDryerPowerCalculator', [
		function(){
			return {
				restrict: 'A',
                controller: function($scope, $modal, DryerPowerService) {

                    $scope.openDryerPowerModal = function() {
        				var modalInstance = $modal.open({
        			    	templateUrl: 'dryerPowerModalContent.html', // Just an identifier, not a file
        			    	controller: 'dryerPowerModalController', // Controller the modal uses
        			    	size: '', // Modal size
        			    	windowClass: 'products-modal',
        			    	scope: $scope // Bind this scope to modal controller as well
        			    });
        			};

                },
				link: function(scope, element, attrs) {
                    element.bind('click', function(e){
                        scope.openDryerPowerModal();
                    });
				}
			};
		}]);

var dryerPowerServices = angular.module('dryerPower.services', []);

dryerPowerServices
	.factory('DryerPowerService', [
	    function(){

            var DryerPowerService = {
                formulaData: function() {
                    return {
                        'kw': {
                            '70': 54,
                            '80': 63.2,
                            '90': 73,
                            '100': 105,
                            '110': 118
                        },
                        'air': {
                            1: 3120,
                            2: 3900 // corn
                        },
                        '2w': {
                            'models': [
                                '22MF2', '33MF2', '44MF2', '55MF2', '65MF2', '75MF2', '85MF2'
                            ],
                            'models_corn': [
                                '22MF2', '33MF2', '44MF2', '55MF2'
                            ],
                            'fan_tamu': {
                            	'22MF2': '8,66/45', 
                            	'33MF2': '8,66/50', 
                            	'44MF2': '17,2/45', 
                            	'55MF2': '17,2/50', 
                            	'65MF2': '2x8,66/50', 
                            	'75MF2': '2x17,2/45', 
                            	'85MF2': '2x17,2/45'
                            },
                            'powers': {
                            	'22MF2': '6,31', 
                            	'33MF2': '8,66', 
                            	'44MF2': '12,54', 
                            	'55MF2': '17,20', 
                            	'65MF2': '17,32', 
                            	'75MF2': '25,08', 
                            	'85MF2': '25,08'
                            }
                        },
                        '3w': {
                            'models': [
                                '23MF3', '33MF3', '44MF3', '55MF3', '65MF3', '75MF3', '85MF3'
                            ],
                            'fan_tamu': {
                            	'23MF3': '8,66/50', 
                            	'33MF3': '17,2/45', 
                            	'44MF3': '2x8,66/50', 
                            	'55MF3': '2x17,2/45', 
                            	'65MF3': '2x17,2/45', 
                            	'75MF3': '2x17,2/50', 
                            	'85MF3': '2x17,2/45 + 8,66/50'
                            },
                            'powers': {
                            	'23MF3': '8,66', 
                            	'33MF3': '12,54', 
                            	'44MF3': '17,32', 
                            	'55MF3': '25,08', 
                            	'65MF3': '25,08', 
                            	'75MF3': '34,40', 
                            	'85MF3': '33,74'
                            }
                        },
                        '4w': {
                            'models': [
                                '44MF4', '55MF4', '65MF4', '75MF4', '85MF4', '103MF4'
                            ],
                            'fan_tamu': {
                            	'44MF4': '2x17,2/45', 
                            	'55MF4': '2x17,2/50',
                            	'65MF4': '2x17,2/45 + 8,66/50', 
                            	'75MF4': '2x17,2/50 + 8,66/50', 
                            	'85MF4': '2x17,2/50 + 17,2/50', 
                            	'103MF4': '2x17,2/50 + 2x17,2/45'
                            },
                            'powers': {
                            	'44MF4': '25,08', 
                            	'55MF4': '34,40', 
                            	'65MF4': '33,74', 
                            	'75MF4': '43,06', 
                            	'85MF4': '51,60', 
                            	'103MF4': '59,48'
                            }
                        }
                    };
                },
                calculate: function(type, temp) {
                    var formulaData = this.formulaData();
                    var results2w = this.getData(formulaData, '2w', type, temp);
                    var results3w = this.getData(formulaData, '3w', type, temp);
                    var results4w = this.getData(formulaData, '4w', type, temp);
                    return {
                        '2w': results2w,
                        '3w': results3w,
                        '4w': results4w
                    };
                },
                getData: function(formulaData, machine, type, temp) {
                    var machineData = formulaData[machine];

                    var results = {
                        'dryer': machine,
                        'power': {},
                        'keys': function(){
                        	if(machineData['models_corn'] && type == 2) {
                        		return machineData['models_corn'];
                        	}
                        	return machineData['models'];
                        },
                        'air': {},
                        'fan_tamu': machineData['fan_tamu'],
                        'powers': machineData['powers']
                    };

                    // kw per cell
                    var kw = formulaData['kw'][temp];
                    var air = formulaData['air'][type];

                    for(var i = 0; i < machineData.models.length; i++) {
                        results['power'][machineData.models[i]] = this.getKw(machineData.models[i], kw);
                        results['air'][machineData.models[i]] = this.getAir(machineData.models[i], air);
                    }

                    return results;
                },
                getKw: function(model, kw) {
                    var cellCount = this.getCellCount(model);
                    return Math.ceil(cellCount * kw);
                },
                getAir: function(model, air) {
                    var cellCount = this.getCellCount(model);
                    return cellCount * air;
                },
                getCellCount: function(model) {
                    var matches = model.match(/(\d+)MF(\d+)/);
                    var num = matches[1].substring(0, matches[1].length - 1);
                    return parseInt(num) * parseInt(model[model.length - 1]);
                }
            };

            return DryerPowerService;

        }]
    );
